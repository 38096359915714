import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { baseApi } from '_services/baseApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import appSlice from '_redux/appSlice';
import { RegisterSlice } from './register/register.slice';
import events from '_redux/partnerEventsPage/partnerEventsPage.slice';
import { adminSlice } from './admin/admin.slice';
import { darkModeSlice } from './darkMode/darkMode.slice';
import { cheatsSlice } from './cheats/cheats.slice';
import { forumSlice } from './forum/forum.slice';
import { drugoeDeloSlice } from './drugoeDelo/drugoeDelo.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { portfolioEdit } from '@/features/portfolioEdit/model';
import { Toast } from '@r-youth/crm-ui';
import { toast } from 'react-toastify';
import { parseResponseErrors } from '@/shared/utils/parseResponseErrors';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  register: RegisterSlice.reducer,
  admin: adminSlice.reducer,
  app: appSlice,
  events,
  darkMode: darkModeSlice.reducer,
  cheats: cheatsSlice.reducer,
  forum: forumSlice.reducer,
  drugoeDelo: drugoeDeloSlice.reducer,
  portfolioEdit,
});

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const isAdminPages = window.location.pathname.includes('admin');
    const flattenedErrors = parseResponseErrors(
      action?.payload?.data?.errors ||
        action?.payload?.data?.message ||
        action?.payload?.data?.error,
    );

    if (isAdminPages) {
      flattenedErrors?.forEach((error) => Toast.error(error));
    } else {
      flattenedErrors?.forEach((error) => toast.error(error));
    }
  }

  return next(action);
};

type SuccessResponse = { data: Record<string, any> };

export const isSuccessResponse = (res: any): res is SuccessResponse => {
  if (res?.error?.data?.error || res?.error?.data?.errors) {
    return false;
  }

  return true;
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware, rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
