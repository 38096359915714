import classes from './RegisterStepEmail.module.scss';
import React, { useState } from 'react';
import { Box, FormControlLabel } from '@material-ui/core';
import Button from '@/shared/ui/Button/Button';
import TextField from '@/shared/ui/TextField/TextField';
import RegisterFormContainer from '@/pages/AuthPage/RegisterPage/RegisterFormContainer';
import Link from '@/shared/ui/Link/Link';
import VKAuthButton from '@/widgets/VKAuthButton/VKAuthButton';
import { cn } from '@/shared/utils/cn';
import Checkbox from '@/shared/ui/Checkbox/Checkbox';
import { MyPersonalDataAgreement } from '@/features/personal-data-agreement';

const RegisterStepEmailEnter = (props) => {
  const { loading, formik } = props;
  const [agreements, setAgreements] = useState({
    politics: true,
    userAgreement: true,
  });

  return (
    <RegisterFormContainer className={classes.root}>
      <h1 className={classes.title}>Регистрация</h1>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div>
          <TextField name={'email'} label={'Электронная почта'} formik={formik} />
          <p className={cn(classes.politics, 'mt-4')}>
            Нажимая на кнопку «Продолжить» или «Войти через VK ID»,
          </p>
          <div className='flex flex-col gap-4 mt-[16px]'>
            <FormControlLabel
              classes={{
                label: 'text-[12px] text-[#313131]',
              }}
              control={<Checkbox variant={'admin'} checked={agreements.politics} />}
              onChange={(_, checked) => setAgreements((prev) => ({ ...prev, politics: checked }))}
              label={
                <>
                  Я принимаю условия{' '}
                  <a
                    href='/Пользовательское соглашение.pdf'
                    className='text-blue font-bold underline underline-offset-4'
                    target='_blank'
                  >
                    Пользовательского соглашения
                  </a>
                </>
              }
            />

            <MyPersonalDataAgreement
              checked={agreements.userAgreement}
              onChange={(_, checked) =>
                setAgreements((prev) => ({ ...prev, userAgreement: checked }))
              }
            />
          </div>
        </div>

        <Button
          type={'submit'}
          size={'small'}
          disabled={loading || !agreements.politics || !agreements.userAgreement || !formik.isValid}
        >
          Продолжить
        </Button>
        <VKAuthButton size={'small'} />
      </form>

      <Box className={classes.redirectLink}>
        <span>Уже есть профиль?&nbsp;</span>
        <Link to={'/auth/signin'} keepQuery>
          Вход
        </Link>
      </Box>
    </RegisterFormContainer>
  );
};

export default RegisterStepEmailEnter;
