import Checkbox from '@/shared/ui/Checkbox/Checkbox';
import FormControlLabel, {
  FormControlLabelProps,
} from '@/shared/ui/FormControlLabel/FormControlLabel';

export const MyPersonalDataAgreement = ({ onChange, checked }: Partial<FormControlLabelProps>) => {
  return (
    <FormControlLabel
      classes={{
        label: 'text-[12px] text-[#313131]',
      }}
      control={<Checkbox variant={'admin'} checked={checked} />}
      onChange={onChange}
      label={
        <>
          Я даю{' '}
          <a
            href='/Согласие_на_обработку_персональных_данных.pdf'
            className='text-blue font-bold underline underline-offset-4'
            target='_blank'
          >
            согласие на обработку моих персональных данных
          </a>{' '}
          в порядке и на условиях, указанных в{' '}
          <a
            href='/Политика_в_отношении_обработки_персональных_данных.pdf'
            className='text-blue font-bold underline underline-offset-4'
            target='_blank'
          >
            Политике конфиденциальности
          </a>
        </>
      }
    />
  );
};
