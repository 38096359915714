import classes from './FormControlLabel.module.scss';
import React from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core';
import { ClassName } from '@/models/ClassName/ClassName';

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  variant?: 'default' | 'admin';
}

const FormControlLabel = (props: FormControlLabelProps) => {
  const { variant = 'default', className, ...muiProps } = props;

  const _className = new ClassName<typeof variant>(variant)
    .add(className)
    .add(classes.root)
    .addVariant({
      variant: 'admin',
      className: classes.variantAdmin,
    })
    .toString();

  return <MuiFormControlLabel className={_className} {...muiProps} />;
};

export default FormControlLabel;
