import React from 'react';
import classes from './Dialog.module.scss';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import classnames from 'classnames';
import { Close } from '@material-ui/icons';
import { combineClasses } from '@/shared/utils/combineClasses';

export interface DialogProps extends MuiDialogProps {
  showCloseAction?: boolean;
  autoFullScreenOnMobile?: boolean;
}

const Dialog = (props: DialogProps) => {
  const {
    showCloseAction = true,
    autoFullScreenOnMobile = false,
    fullScreen,
    ...dialogProps
  } = props;
  const mdDown = useMediaQuery('(max-width: 960px)');
  const _fullScreen = fullScreen || (autoFullScreenOnMobile && mdDown);

  const _classes = combineClasses(props.classes, {
    root: classnames(classes.root, { [classes.rootFullscreen]: _fullScreen }),
    paper: classes.paper,
    paperFullScreen: classes.paperFullScreen,
  });

  const handleCloseClick = (e) => {
    props.onClose(e, 'escapeKeyDown');
  };

  return (
    <MuiDialog
      {...dialogProps}
      style={{ zIndex: 100000 }}
      fullScreen={_fullScreen}
      classes={_classes}
    >
      {showCloseAction && (
        <IconButton className={classnames(classes.closeIcon)} onClick={handleCloseClick}>
          <Close />
        </IconButton>
      )}
      <div className={classnames('grow', { [classes.contentContainerFullScreen]: _fullScreen })}>
        {props.children}
      </div>
    </MuiDialog>
  );
};

export default Dialog;
