// @ts-nocheck
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useMemo } from 'react';
import { theme } from '@/app/themes/themeInstance';
import AppRoutes, { browserRouter } from '@/app/AppRoutes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import '../fonts/fonts.css';
import 'moment/locale/ru';
import TextExplosionEasterEgg from '@/widgets/UbahEasterEgg/TextExplosionEasterEgg';
import { StylesProvider } from '@material-ui/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useAppUpdateNotification } from '@/hooks/useAppUpdateNotification';
import { useDarkMode } from '@/hooks/useDarkMode';
import { useUserActivityLogger } from '@/hooks/useUserActivityLogger';
import { useReferralLinks } from '@/hooks/useReferralLinks';
import { useUTMCookie } from '@/hooks/useUTMCookie';
import { BpThemeProvider, createTheme } from '@r-youth/crm-ui';
import { Link, NavigationType } from 'react-router-dom';
import { getSimpleBack } from '@/hooks/useRouterUtils';
moment.locale('ru');

// browserRouter.subscribe((state) => {
//   if (state.historyAction === NavigationType.Pop) {
//     if (['create', 'new', 'add'].some((it) => state.location.pathname.includes(it))) {
//       browserRouter.navigate(getSimpleBack(state.location.pathname));
//     }
//   }
// });

export const CRMThemeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const theme = useMemo(() => {
    return createTheme({
      type: 'tx',
      LinkElement: Link,
      handlerBackButton: () => browserRouter.navigate(-1),
    });
  }, []);

  return <BpThemeProvider theme={theme}>{children}</BpThemeProvider>;
};

const App = () => {
  useDarkMode();
  useUserActivityLogger();
  useReferralLinks();
  useUTMCookie();
  useAppUpdateNotification();

  return (
    <CRMThemeProvider>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
            <TextExplosionEasterEgg>
              <AppRoutes />
            </TextExplosionEasterEgg>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </MuiThemeProvider>
    </CRMThemeProvider>
  );
};

export default App;
